.Cart {
  margin: 0 10vw;
  display: flex;
}

.Cart > .list {
  flex: 0.7;
}

.Cart > .list > .head {
  font-weight: lighter;
  padding: 1rem;
}

.Cart > .list > .CartCard {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.2);
  padding: 1rem 2rem 1rem 5rem;
}

.Cart > .price {
  flex: 0.3;
  margin: 1rem 0.75rem;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.2);
}

.Cart > .price > .head {
  font-size: large;
}

.Cart > .price > .total {
  font-size: large;
  min-height: 1.5rem;
  margin-top: 1rem;
  font-weight: bolder;
}

.Cart > .price > .disc {
  min-height: 1.5rem;
  font-size: smaller;
  color: green;
}

.Cart > .price button {
  background: orange;
  color: white;
  display: block;
  padding: 0.5rem 1.5rem;
  width: 100%;
}

.Cart > .list > .CartCard > .author {
  color: gray;
  font-size: small;
}
.Cart > .list > .CartCard > .amount {
  font-weight: bold;
}
.CartCard > .left > {
  margin-right: 8rem;
}
.CartCard {
  display: flex;
  justify-content: space-around;
}
.CartCard > .right {
  margin-left: 8rem;
  justify-content: space-evenly;
}
.CartCard > .right .wish {
  margin-left: 10rem;
  color: #1873ce;
  font-size: smaller;
}
.CartCard > .right .amount {
  margin-left: 1rem;
  margin-right: -6rem;
}
.CartCard > .right .delete {
  margin-left: 7rem;
}
.CartCard .amount {
  font-weight: bold;
}
