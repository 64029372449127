ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: space-around;
}
.left {
  width: 20%;
}
.right {
  width: 80%;
  display: flex;
  justify-content: flex-end;
}

.right > li {
  padding: 0.6rem;
}

.right > li > img {
  margin-top: -0.3rem;
  margin-right: 1rem;
}
a {
  text-decoration: none;
}
.right > li > a > h5 {
  margin-top: -0.3rem;
  margin-right: 0.5rem;
}
.right > li > h5 {
  margin-top: -0.3rem;
  margin-right: 0.5rem;
}
