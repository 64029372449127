.modal {
  width: 50%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  position: fixed;
  top: 40vh;
  padding: 5vh 5vw;
  text-align: center;
  left: 25%;
  z-index: 100;
}
