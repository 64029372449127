.HomePage {
  margin: 0 10vw;
  display: flex;
  flex-direction: row;
}

.HomePage > .list {
  flex: 0.7;
}

.HomePage > .list > .header {
  display: flex;
  margin: 1rem;
  align-items: center;
  justify-content: space-evenly;
}

.HomePage #currentPrice {
  background: white;
  border: 1px solid #000;
  padding: 0.5rem 2rem 0.5rem 1rem;
  cursor: pointer;
}

.HomePage > .cartDetails {
  flex: 0.3;
}
