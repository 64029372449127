.Heading {
  margin-right: 140px;
  margin-left: 140px;
  background: #0f1317;
  top: 76px;
  height: 111px;
  margin-top: -1.2rem;
}
.Heading > p {
  width: 20%;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  margin-left: 1rem;
  padding: 2rem;
}
