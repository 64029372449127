/* giving the border and box shadow for cart */
.CartDetails {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.2);
  margin: 2.75rem 0.75rem;
  height: 60vh;
  position: relative;
}

.CartDetails > h3 {
  text-align: center;
  padding: 1.25rem 0;
  font-weight: lighter;
}

.CartDetails > .cartCardList {
  position: absolute;
  overflow-y: auto;
  right: 0;
  left: 0;
  top: 5rem;
  display: flex;
  flex-direction: column;
}

.CartDetails > .cartCardList > .cartCard {
  padding: 0.5rem 1rem;
  margin: 0 1rem;
  margin-bottom: 1rem;
  border-bottom-style: solid;
  border-bottom-color: rgb(179, 178, 178);
  border-bottom-width: 1px;
}

.CartDetails > .cartCardList > .cartCard > .price {
  font-weight: bold;
  font-size: large;
  text-align: right;
}

.CartDetails > .cartCardList > .cartCard > .name {
  font-weight: lighter;
}

.CartDetails > .CartValue {
  position: absolute;
  bottom: 0;
  right: 0;
  border-top-color: rgb(148, 141, 141);
  border-top-width: 1px;
  border-top-style: solid;
  width: 100%;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-evenly;
}

.CartValue h6 {
  font-size: large;
  min-height: 1rem;
}

.CartValue a {
  color: orange;
}
