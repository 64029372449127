.WishList {
  margin: 0 10vw;
  display: flex;
}

.WishList > .list {
  flex: 0.65;
}

.WishList > .list > .head {
  font-weight: lighter;
  padding: 1rem;
}

.WishList > .list > .Wish {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.2);
  padding: 1rem 2rem 1rem 5rem;
}

.Wish {
  display: flex;
  justify-content: space-between;
}

.Wish > .img {
  width: 1rem;
}

.Wish > .information .tags {
  display: inline-block;
  background: #ff6738;
  color: white;
  padding: 0.5rem 0.75rem;
  margin: 1rem 0.75rem 0rem 0rem;
  width: fit-content;
}

.Wish > .author {
  font-size: smaller;
  color: gray;
}

.Wish > .price {
  display: flex;
}

.Wish > .price > .main {
  font-weight: bolder;
}

.Wish > .price > .disc {
  text-decoration: line-through;
  font-size: smaller;
}

.Wish > .addToCart > button {
  background: #ff6738;
  color: white;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
}

.Wish > .delete > img {
  cursor: pointer;
}

.WishList > .cartDetails {
  flex: 0.35;
}
