.CourseDetails {
  position: relative;
}

.CourseDetails > .head {
  margin: 2rem 5vw;
}

.CourseDetails > .header {
  background-color: black;
  margin: 1rem 0;
  padding: 1rem 5rem;
  min-height: 10vh;
}

.CourseDetails > .header > .boldHead {
  color: white;
  font-weight: bold;
  font-size: large;
}

.CourseDetails > .header > .lightHead {
  color: white;
  font-weight: light;
}

.CourseDetails > .header > .author {
  color: orange;
  margin: 1rem 0;
}

.CourseDetails > .body {
  margin: 0 5vw;
  width: 50vw;
  padding: 1rem 5rem 1rem 2rem;
  box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.2);
}

.CourseDetails > .body > p {
  font-size: smaller;
  color: gray;
}

.CourseDetails > .card {
  position: absolute;
  right: 5rem;
  top: 5rem;
  border: 1px solid white;
}

.CourseDetails > .card > .video {
  height: 30vh;
  width: 100%;
  background: black;
}

.CourseDetails > .card > .body {
  padding: 2rem;
  background-color: white;
}

.CourseDetails > .card > .body > .message {
  color: orange;
}
