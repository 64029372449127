/* creating box shadow and border for each of the card */
.CourseList > .CourseCard {
  box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
  display: flex;
  justify-content: space-around;
  padding: 1rem 2rem;
}

.CourseCard > .coname {
  flex: 0.5;
}

.CourseCard > .author {
  color: gray;
  font-size: small;
}

.CourseCard > .price {
  font-weight: bold;
}

.CourseCard > .disc_pric {
  font-size: small;
  color: gray;
  text-decoration: line-through;
}

.CourseCard > .coname > .tags {
  display: inline-block;
  background: #ff6738;
  color: white;
  padding: 0.5rem 0.75rem;
  margin: 1rem 0.75rem 0rem 0rem;
  /* margin-top: 1rem; */
  width: fit-content;
}

.CourseCard > .add > button {
  background: #ff6738;
  color: white;
  padding: 0.5rem 0.75rem;
}
.CourseCard a {
  color: black;
  text-decoration: none;
}
